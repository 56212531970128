import {
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
} from 'react';
import { useRecoilValue } from 'recoil';
import sessionState from 'state/session';
import { useFeatureFlagApi } from 'util/api/featureFlag';
import { FeatureFlag } from 'util/featureFlag';

interface IFeatureFlagContext {
    featureFlags?: FeatureFlag[];
}

const FeatureFlagContext = createContext<IFeatureFlagContext>(null);

export function FeatureFlagProvider({ children }: { children: ReactNode }) {
    const [featureFlags, setFeatureFlags] = useState<FeatureFlag[]>(null);

    const session = useRecoilValue(sessionState);
    const featureFlagApi = useFeatureFlagApi();

    useEffect(() => {
        try {
            if (session?.idToken) {
                const fetchAccountFeatureFlags = async () => {
                    setFeatureFlags(
                        await featureFlagApi.getAccountFeatureFlags()
                    );
                };

                fetchAccountFeatureFlags();
            } else {
                setFeatureFlags([]);
            }
        } catch (e) {
            console.error('Error Fetching Feature Flags', e);
            setFeatureFlags([]);
        }
    }, [featureFlagApi, session, setFeatureFlags]);

    return (
        <FeatureFlagContext.Provider value={{ featureFlags }}>
            {children}
        </FeatureFlagContext.Provider>
    );
}

export function useFeatureFlagContext() {
    return useContext(FeatureFlagContext);
}
