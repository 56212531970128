import { ReactNode, Suspense as ReactSuspense } from 'react';
import classNames from 'util/classNames';
import Loader from '../Loader';
import styles from './Suspense.module.scss';

export function SuspenseFallback() {
    return (
        <div className={classNames(styles.overlay, styles.appDisabled)}>
            <Loader className={styles.loader} />
        </div>
    );
}

export function Suspense({ children }: { children: ReactNode }) {
    return (
        <ReactSuspense fallback={<SuspenseFallback />} children={children} />
    );
}
