import * as axios from 'axios';
import qs from 'querystring';
import { useEffect, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';
import session from 'state/session';
import getLogger from 'util/Log';

enum HttpMethod {
    get = 'get',
    post = 'post',
    put = 'put',
    delete = 'delete',
}

export default class HttpApiClient {
    private log;
    private setSession: any;
    private _headers;
    private _base_url;
    constructor(headers, base_url?) {
        this.log = getLogger('api');
        this._headers = headers || {};
        this._base_url =
            base_url || `${process.env.REACT_APP_BRIDEWATER_API_HOST}`;
    }

    private get headers() {
        const idToken = localStorage.getItem('auth.idToken');
        return {
            Authorization: idToken ? `Bearer ${idToken}` : undefined,
            ...this._headers,
        };
    }
    private send = async <TRequest, TResponse>(
        route: string,
        method: HttpMethod,
        payload?: TRequest,
        isRetry?: boolean
    ) => {
        try {
            const config = {
                headers: this.headers,
            };
            const response = await axios[method](
                `${this._base_url}${route}`,
                payload || config,
                config
            );
            return response.data as TResponse;
        } catch (e) {
            this.log.error(`error fetching: [${method}:${route}]`, e);
            throw e;
        }
    };

    private constructUrl(route, query) {
        return !query ? route : `${route}?${qs.stringify(query)}`;
    }

    setSessionHandler = (setSession) => {
        this.setSession = setSession;
    };

    get = async <TResponse>(route: string, query?: Object) => {
        return await this.send<null, TResponse>(
            this.constructUrl(route, query),
            HttpMethod.get
        );
    };

    post = async <TResponse, TRequest>(
        route: string,
        payload: TRequest,
        query?: Object
    ) => {
        return (await this.send(
            this.constructUrl(route, query),
            HttpMethod.post,
            payload
        )) as TResponse;
    };

    put = async <TRequest, TResponse>(
        route: string,
        payload: TRequest,
        query?: Object
    ) => {
        return (await this.send(
            this.constructUrl(route, query),
            HttpMethod.put,
            payload
        )) as TResponse;
    };

    delete = async (route: string, objectId: string, query?: Object) => {
        try {
            await this.send(
                this.constructUrl(`${route}/${objectId}`, query),
                HttpMethod.delete
            );
        } catch (err) {
            this.log.error(
                'error deleting object',
                route,
                objectId,
                query,
                err
            );
            throw err;
        }
    };
}

export const useHttpClient = <T = {}>(
    headers: T = undefined,
    base_url: string = undefined
) => {
    const api = useMemo(
        () => new HttpApiClient(headers, base_url),
        [headers, base_url]
    );
    const setSession = useSetRecoilState(session);
    useEffect(() => {
        api.setSessionHandler(setSession);
    }, [api, setSession]);
    return api;
};
