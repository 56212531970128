import { navigate } from 'components/display/Router';
import { NavigationPage } from 'pages/NavigationPage';
import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { registrationState } from 'state/setup';
import { useProfileApi } from 'util/api/profile';
import { useOpenPassClient } from 'util/OpenPass';

export default function OpenPassRedirect() {
    const { handleRedirect } = useOpenPassClient();
    const regState = useRecoilValue(registrationState);
    const profileApi = useProfileApi();
    useEffect(() => {
        (async () => {
            const clientState = await handleRedirect();
            if (regState.hasRegisteredDevice) {
                const profiles = await profileApi.getProfiles();
                if (profiles.length) {
                    return navigate(NavigationPage.Success);
                }

                return navigate(
                    `${NavigationPage.Profile}/${NavigationPage.NewProfile}`
                );
            }
            if (clientState?.pathname) {
                return navigate(
                    `${clientState.pathname}${clientState.search}`,
                    false
                );
            }
            navigate(NavigationPage.Home, false);
        })();
    }, [handleRedirect, profileApi, regState]);
    return null;
}
