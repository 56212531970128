import { atom, selector } from 'recoil';

const defaultIdToken = localStorage.getItem('auth.idToken');
const defaultEmail = localStorage.getItem('auth.email');
const defaultExpires = parseInt(localStorage.getItem('auth.expires') || '0');
const defaultRefreshToken = localStorage.getItem('auth.refreshToken');

const IdToken = atom({
    key: 'UserSessionState.idToken',
    default: defaultIdToken,
});
const RefreshToken = atom({
    key: 'UserSessionState.refreshToken',
    default: defaultRefreshToken,
});
const Email = atom({
    key: 'UserSessionState.email',
    default: defaultEmail,
});

const Expires = atom({
    key: 'UserSessionState.expires',
    default: defaultExpires,
});

export type UserSession = {
    email?: string;
    idToken?: string;
    refreshToken?: string;
    expires?: number;
};

export default selector<UserSession>({
    key: 'UserSessionState.Handler',
    get({ get }) {
        const idToken = get(IdToken);
        const exp = get(Expires);
        const needsRefresh = exp < Math.floor(new Date().getTime() / 1000);

        return idToken && !needsRefresh
            ? {
                  idToken,
                  refreshToken: get(RefreshToken),
                  expires: get(Expires),
                  email: get(Email),
              }
            : null;
    },
    set({ set }, newValue) {
        const { email, idToken, refreshToken, expires }: UserSession =
            (newValue as UserSession) || {};
        localStorage.setItem('auth.idToken', idToken || '');
        localStorage.setItem('auth.email', email || '');
        localStorage.setItem('auth.refreshToken', refreshToken || '');
        localStorage.setItem('auth.expires', expires?.toString() || '0');

        set(Email, email);
        set(RefreshToken, refreshToken);
        set(Expires, expires);
        set(IdToken, idToken);
    },
});
