import Logger from 'util/Log';
const { trace } = Logger('navigate.ts');

export function navigate(
    to: string | string[],
    preserveSearch: boolean = true,
    newState = {},
    replaceState: boolean = false
) {
    const path =
        (typeof to === 'string' ? to : (to as string[]).join('/')) +
        (preserveSearch ? window.location.search : '');
    trace('path', path);
    window.history[replaceState ? 'replaceState' : 'pushState'](
        newState,
        '',
        path
    );
    window.dispatchEvent(new PopStateEvent('popstate'));
    window.scrollTo(0, 1);
}

export function navigateAbsolute(
    to: string | string[],
    preserveSearch: boolean = true,
    newState = {},
    replaceState: boolean = false
) {
    trace('navigateAbsolute args', to, preserveSearch, newState);
    let path = typeof to === 'string' ? `/${to}` : ['', ...to];
    trace('navigateAbsolute path', path);
    return navigate(path, preserveSearch, newState, replaceState);
}
