import { useRef } from 'react';
import Api, { useHttpClient } from './api';

class AuthApi {
    private api: Api;
    constructor(api) {
        this.api = api;
    }

    signIn = async (idToken: string) => {
        return (await this.api.post('/v1/auth', {
            grantType: 'signin',
            idToken,
        })) as { idToken: string; refreshToken: string };
    };
}

const useAuthApi = () => {
    const httpClient = useHttpClient();
    const authApi = useRef(new AuthApi(httpClient));
    return authApi.current;
};

export { useAuthApi };
