import { useEffect, useState } from 'react';
import { useFeatureFlagContext } from 'state/featureFlag';
import { FeatureFlag } from 'util/featureFlag';

export default function useFeatureFlag(flagName: FeatureFlag): boolean {
    const { featureFlags } = useFeatureFlagContext();
    const [isFeatureEnabled, setIsEnabled] = useState(null);

    useEffect(() => {
        if (!featureFlags) return;
        if (featureFlags.includes(flagName) || !flagName) {
            setIsEnabled(true);
        } else {
            setIsEnabled(false);
        }
    }, [flagName, featureFlags, setIsEnabled]);

    return isFeatureEnabled;
}
