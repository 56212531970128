import { atom, selector } from 'recoil';
import { logOnPage, stringifySafely } from 'util/Log/Logger';

function getDefaultValue(key) {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (e) {
        return false;
    }
}
function getStateAtom(key) {
    const newKey = `registration.${key}`;
    return atom<boolean>({
        key: newKey,
        default: getDefaultValue(newKey),
    });
}

const hasRegisteredDevice = getStateAtom('hasRegisteredDevice'),
    hasSignedUp = getStateAtom('hasSignedUp'),
    hasSignedIn = getStateAtom('hasSignedIn'),
    hasCompletedSetup = getStateAtom('hasCompletedSetup'),
    hasCompletedEmailVerification = getStateAtom(
        'hasCompletedEmailVerification'
    ),
    hasCreatedAMainProfile = getStateAtom('hasCreatedAMainProfile');

const registrationState = selector<{
    hasRegisteredDevice?: boolean;
    hasSignedUp?: boolean;
    hasSignedIn?: boolean;
    hasCreatedAMainProfile?: boolean;
    hasCompletedSetup?: boolean;
    hasCompletedEmailVerification?: boolean;
}>({
    key: 'registration.stateMachine',
    get: ({ get }) => {
        let state = {
            hasRegisteredDevice: get(hasRegisteredDevice),
            hasSignedUp: get(hasSignedUp),
            hasSignedIn: get(hasSignedIn),
            hasCreatedAMainProfile: get(hasCreatedAMainProfile),
            hasCompletedSetup: get(hasCompletedSetup),
            hasCompletedEmailVerification: get(hasCompletedEmailVerification),
        };
        logOnPage(`get.state: ${stringifySafely(state)}, len = ${localStorage.length}`);
        return state;
    },
    set: ({ set }, newValue) => {
        Object.keys(newValue).forEach((key) => {
            localStorage.setItem(`registration.${key}`, newValue[key]);
            switch (key) {
                case 'hasRegisteredDevice':
                    set(hasRegisteredDevice, newValue[key]);
                    break;
                case 'hasSignedUp':
                    set(hasSignedUp, newValue[key]);
                    break;
                case 'hasSignedIn':
                    set(hasSignedIn, newValue[key]);
                    break;
                case 'hasCreatedAMainProfile':
                    set(hasCreatedAMainProfile, newValue[key]);
                    break;
                case 'hasCompletedSetup':
                    set(hasCompletedSetup, newValue[key]);
                    break;
                case 'hasCompletedEmailVerification':
                    set(hasCompletedEmailVerification, newValue[key]);
                    break;
                default:
                    break;
            }
            logOnPage(`set.state: ${stringifySafely(newValue)}, len = ${localStorage.length}`);
        });
    },
});

export {
    registrationState,
    hasRegisteredDevice,
    hasSignedIn,
    hasSignedUp,
    hasCreatedAMainProfile,
    hasCompletedSetup,
};
