import { useEffect, useMemo, useRef } from 'react';
import { useState } from 'react';
import { ReactNode } from 'react';
import { createPortal } from 'react-dom';
import styles from './Dialog.module.scss';
import close from 'images/close.svg';
import Button from '../Button';
interface DialogProps {
    children: ReactNode;
    content: ReactNode;
    isOpen?: boolean;
    className?: string;
}

export default function Dialog({
    children,
    content,
    isOpen: _isOpen = false,
    className,
}: DialogProps) {
    const [isOpen, setIsOpen] = useState(false);
    let portalRef = useRef<HTMLDivElement>();
    let el = useMemo(() => document.createElement('div'), []);

    useEffect(() => {
        const handler = () => setIsOpen(false);
        window.addEventListener('popstate', handler);
        return () => window.removeEventListener('popstate', handler);
    }, []);

    useEffect(() => {
        el.classList.add(styles.dialogWrapper);
        document.getElementById('dialog-root').appendChild(el);
        let timeout;
        const clickAwayHandler = (event: MouseEvent) => {
            if (!isOpen) {
                return;
            }
            if (
                !isOpen ||
                portalRef.current?.contains(event.target as Node) ||
                event.target === portalRef.current
            ) {
                return;
            }
            portalRef.current?.classList.add(styles.dialogClosed);
            timeout = setTimeout(() => setIsOpen(false), 500);
        };

        document.addEventListener('click', clickAwayHandler);
        return () => {
            clearTimeout(timeout);
            document.removeEventListener('click', clickAwayHandler);
            document.getElementById('dialog-root').removeChild(el);
        };
    }, [el, isOpen]);

    return (
        <>
            <span
                onClick={(e) => {
                    e.preventDefault();
                    setTimeout(() => setIsOpen(true));
                }}
                className={className}
            >
                {children}
            </span>
            <Portal
                content={
                    <div className={styles.dialog} ref={portalRef}>
                        <Button
                            isFlat
                            onClick={() => {
                                portalRef.current?.classList.add(
                                    styles.dialogClosed
                                );
                                setTimeout(() => setIsOpen(false), 500);
                            }}
                            className={styles.closeButtonButton}
                        >
                            <img
                                src={close}
                                alt={'close'}
                                className={styles.closeButton}
                            />
                        </Button>
                        {content}
                    </div>
                }
                element={el}
                isOpen={isOpen}
            />
        </>
    );
}

function Portal({ content, element, isOpen }) {
    return isOpen ? createPortal(content, element) : null;
}
