import { useContext } from 'react';
import { createContext } from 'react';
import locationProvider from './locationProvider';

const RouteContext = createContext<{
    path: string;
    params: { [key: string]: string };
}>({ path: '', params: {} });

export default function RouteContextProvider({ children, path, params }) {
    return (
        <RouteContext.Provider value={{ path, params }}>
            {children}
        </RouteContext.Provider>
    );
}

export function useRouteContext(pathName?: string) {
    const parentPath = useContext(RouteContext) ?? { path: '', params: {} };
    const pathTemplate = `${parentPath.path}/${pathName}`;
    const knownPath = locationProvider()
        .pathname.split('/')
        .filter(Boolean)
        .splice(0, pathTemplate.split('/').filter(Boolean).length);
    return {
        path: `${parentPath.path}/${pathName}`,
        params: parentPath.params,
        knownPath,
    };
}

export { RouteContext };
