import { ILogger } from './ILogger';

enum LogLevel {
    Off = 'Off',
    On = 'On',
}
class ConsoleLogger implements ILogger {
    // turn on console logging by loading the page with ?loglevel=on
    private level: LogLevel = window.location.search
        ?.toLowerCase()
        .includes('loglevel=on')
        ? LogLevel.On
        : LogLevel.Off;

    setLogLevel(level: LogLevel) {
        this.level = level;
    }

    debug: (...args: unknown[]) => void = (first, ...rest) => {
        if (this.level !== LogLevel.On) return;

        console.debug(`%c${first}`, 'color: hsl(140,40%,55%);', ...rest);
    };
    trace: (...args: unknown[]) => void = (first, ...rest) => {
        if (this.level !== LogLevel.On) return;

        console.trace(`%c${first}`, 'color: hsl(206,85%,57.5%);', ...rest);
    };
    info: (...args: unknown[]) => void = (first, ...rest) => {
        if (this.level !== LogLevel.On) return;

        console.info(`%c${first}`, 'color: hsl(206,98.5%,29%);', ...rest);
    };
    warn: (...args: unknown[]) => void = (first, ...rest) => {
        if (this.level !== LogLevel.On) return;

        console.warn(`%c${first}`, 'color: hsl(27,90%,50%)', ...rest);
    };
    error: (...args: unknown[]) => void = (first, ...rest) => {
        if (this.level !== LogLevel.On) return;

        console.error(`%c${first}`, 'color: hsl(358,62%,52%)', ...rest);
    };
}

const consoleLogger = new ConsoleLogger();

export default consoleLogger;

window['startLogging'] = () => consoleLogger.setLogLevel(LogLevel.On);
window['stopLogging'] = () => consoleLogger.setLogLevel(LogLevel.Off);
