import { useRef } from 'react';
import Api, { useHttpClient } from './api';

class FeatureFlagApi {
    private api;
    constructor(api: Api) {
        this.api = api;
    }

    getAllFeatureFlags = async () => {
        return await this.api.get('/v1/feature-flag');
    };

    getAccountFeatureFlags = async () => {
        return await this.api.get('/v1/account/features');
    };
}

const useFeatureFlagApi = () => {
    const httpClient = useHttpClient();
    const featureFlagApi = useRef(new FeatureFlagApi(httpClient));
    return featureFlagApi.current;
};

export { useFeatureFlagApi };
