import { useContext, useMemo } from 'react';
import { useState } from 'react';
import { ReactNode } from 'react';
import { createContext } from 'react';

interface IFormContext {
    setFormIsDisabled: () => void;
    setFormIsEnabled: () => void;
    isFormEnabled: boolean;
}

const FormContext = createContext<IFormContext>(null);

export function FormStateProvider({ children }: { children: ReactNode }) {
    const [isFormEnabled, setIsEnabled] = useState(true);

    const setFormIsDisabled = useMemo(
        () => () => setIsEnabled(false),
        [setIsEnabled]
    );
    const setFormIsEnabled = useMemo(
        () => () => setIsEnabled(true),
        [setIsEnabled]
    );

    return (
        <FormContext.Provider
            value={{
                setFormIsDisabled,
                setFormIsEnabled,
                isFormEnabled,
            }}
        >
            {children}
        </FormContext.Provider>
    );
}

export function useFormContext() {
    return useContext(FormContext);
}
