import classNames from 'util/classNames';
import Button from '../Button';
import styles from './LogOut.module.scss';
import { useRecoilValue } from 'recoil';
import sessionState from 'state/session';
import { useOpenPassClient } from 'util/OpenPass';

interface LogoutProps {
    className?: string;
}
export default function LogOut({ className }: LogoutProps) {
    const session = useRecoilValue(sessionState);
    const { signOut } = useOpenPassClient();
    if (session.idToken) {
        return (
            <Button
                onClick={signOut}
                isFlat
                className={classNames(className, styles.button)}
            >
                Sign Out
            </Button>
        );
    }
    return <div></div>;
}
