import { ILogger } from './ILogger';
let middleWare: Set<ILogger> = new Set();

class Logger implements ILogger {
    constructor(prefix: string) {
        this.prefix = prefix;
    }
    private prefix!: string;
    private doLog = (callback) => Array.from(middleWare).forEach(callback);

    trace: (...args: unknown[]) => void = (...args) => {
        this.doLog((logger) => logger.trace(this.prefix, ...args));
    };

    debug: (...args: unknown[]) => void = (...args) => {
        this.doLog((logger) => logger.debug(this.prefix, ...args));
    };

    info: (...args: unknown[]) => void = (...args) => {
        this.doLog((logger) => logger.info(this.prefix, ...args));
    };

    warn: (...args: unknown[]) => void = (...args) => {
        this.doLog((logger) => logger.warn(this.prefix, ...args));
    };

    error: (...args: unknown[]) => void = (...args) => {
        this.doLog((logger) => logger.error(this.prefix, ...args));
    };
}

export default function logFactory(prefix: string) {
    return new Logger(`BridgeWater: ${prefix}`);
}

export function injectMiddleWare(loggerMiddleWare: ILogger) {
    middleWare.add(loggerMiddleWare);
}

// Safari doesn't support lookbehind yet
// const tokenRegex = /(?<=(token|email|secret)[^"]*":")[^"]+(?=")/gi;
// const tokenRegex = /((token|email|secret)[^"]*":")[^"]*(")"/gi;
const tokenRegex = /((token|email|secret)[^"]*":")[^"]+(")/gi;
export const stringifySafely = (ctx: any) => {
    try {
        // 1. to avoid the circular object error by catching the exception
        // 2. strip secret from payload, like refreshToken, idToken
        return JSON.stringify(ctx).replace(tokenRegex, '$1*$3');
    } catch (e) {
        return `JSON.stringify error: ${e}`;
    }
};

export const logOnPage = (message: unknown) => {
    let node = document.getElementById('app-logger');
    let text = `${new Date().toISOString().substring(11)} ${message}`;
    if (node == null) {
        // console.info('LogNotReady: ' + text);
    } else {
        let line = document.createElement('p');
        line.appendChild(document.createTextNode(text));
        node.insertBefore(line, node.firstChild);
    }
};
