import { MouseEventHandler, ReactNode } from 'react';
interface LinkProps {
    to?: string;
    onClick?: MouseEventHandler<HTMLAnchorElement>;
    children: ReactNode;
    preserveSearch?: boolean;
    className?: string;
}
export function Link({
    to,
    onClick,
    children,
    preserveSearch = true,
    className,
}: LinkProps) {
    let _onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
        onClick?.(e);
        if (e.defaultPrevented) {
            return;
        }
        // open in new tab...
        if (e.metaKey || e.ctrlKey) {
            return;
        }
        e.preventDefault();
        if (to) {
            window.history.pushState(
                {},
                '',
                to + (preserveSearch ? window.location.search : '')
            );
            // trigger event so routes can re-render...
            window.dispatchEvent(new PopStateEvent('popstate'));
        }
    };
    return (
        <a href={to} onClick={_onClick} className={className}>
            {children}
        </a>
    );
}
