export enum NavigationPage {
    Welcome = 'welcome',
    ConfirmEmail = 'confirm-email',
    AuthCode = 'code',
    Profile = 'profile',
    Activate = 'activate',
    DescribeYourSelf = 'describe-yourself',
    ProfileType = 'type',
    NewProfile = 'create',
    Success = 'setup-success',
    Home = 'home',
    Device = 'device',
    Debug = 'debug',
    OpenPassRedirect = 'open-pass-redirect',
    Search = 'search',
    Admin = 'admin',
    Feedback = 'feedback',
    Update = 'update',
    Details = 'details',
}
