import { IInterest } from 'pages/Profile/NewProfile/NewProfile';
import { useRef } from 'react';
import { ApiProfile } from 'state/profiles';
import Api, { useHttpClient } from './api';

class ProfileApi {
    private api: Api;
    constructor(api) {
        this.api = api;
    }

    getProfiles = async (): Promise<ApiProfile[]> => {
        return await this.api.get('/v1/profiles');
    };

    create = async (profile: ApiProfile) => {
        return await this.api.post('/v1/profiles', profile);
    };

    update = async (profile: ApiProfile) => {
        return await this.api.put(`/v1/profiles/${profile.profileId}`, profile);
    };

    getAllInterests = async (): Promise<IInterest[]> => {
        return await this.api.get('/v1/profiles/preferences');
    };
}

const useProfileApi = () => {
    const httpClient = useHttpClient();
    const profileApi = useRef(new ProfileApi(httpClient));
    return profileApi.current;
};

export { useProfileApi };
