import { ReactNode, useEffect, useRef, useState } from 'react';
import styles from './toast.module.scss';
import { createPortal } from 'react-dom';
import classNames from 'util/classNames';
import { ReactComponent as Close } from 'images/close.svg';

export enum ToastType {
    Info = 'info',
    Error = 'error',
    Success = 'success',
}

class ToastManager {
    constructor() {
        window.addEventListener('popstate', this.onPopState);
    }

    toast = [] as ReactNode[];
    toastCount = 0;
    handlers = new Set<Function>();

    private processHandlers() {
        this.handlers.forEach((handler) => handler());
    }

    private onPopState = () => {
        this.toast = [];
        this.processHandlers();
    };

    pushToast = (
        message: ReactNode,
        duration = 10000,
        type: ToastType = ToastType.Info
    ) => {
        const _toast = (
            <Toast
                message={message}
                key={this.toastCount++}
                type={type}
                close={() => toastManager.removeToast(_toast)}
            />
        );
        this.toast.push(_toast);
        this.processHandlers();
        if (type !== 'error')
            setTimeout(() => {
                this.toast = this.toast.filter((t) => t !== _toast);
                this.processHandlers();
                toastManager.removeToast(_toast);
            }, duration);
    };

    removeToast = (toast) => {
        this.toast = this.toast.filter((t) => t !== toast);
        this.processHandlers();
    };
    getToast = () => {
        return [...this.toast];
    };
    subscribe = (handler) => {
        this.handlers.add(handler);
        return () => {
            this.handlers.delete(handler);
        };
    };
}

function Toast({ message, type = 'info', close }) {
    const _this = useRef<HTMLDivElement>();
    return (
        <div ref={_this} className={classNames(styles.toast, styles[type])}>
            <Close className={styles.closer} onClick={close} role='button' />
            {message}
        </div>
    );
}

const toastManager = new ToastManager();

export default function toast(
    message: ReactNode,
    {
        type,
        durationInMilliseconds = 10000,
    }: {
        durationInMilliseconds?: number;
        closeable?: boolean;
        type?: ToastType;
    } = {
        durationInMilliseconds: 10000,
        type: ToastType.Info,
    }
) {
    toastManager.pushToast(message, durationInMilliseconds, type);
}

export function ToastContainer() {
    const [, render] = useState(0);
    useEffect(() => {
        return toastManager.subscribe(() => render((n) => n + 1));
    }, []);

    return createPortal(
        <div className={styles.toastContainer}>{toastManager.getToast()}</div>,
        document.body
    );
}

export { toastManager };
