import styles from './Loader.module.scss';
import classNames from 'util/classNames';

export default function Loader({ className }: { className?: string }) {
    return (
        <div className={classNames(styles.loader, className)}>
            <div className={styles.loaderChild} />
            <div
                className={classNames(
                    styles.loaderChild,
                    styles.loaderSecondChild
                )}
            />
        </div>
    );
}
