import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { RouteContext } from './RouteContext';
import RouteContextProvider, { useRouteContext } from './RouteContext';
import locationProvider from './locationProvider';
import { FeatureFlag } from 'util/featureFlag';
import useFeatureFlag from 'components/hooks/useFeatureFlag';
import { navigateAbsolute } from './navigate';
import { NavigationPage } from 'pages/NavigationPage';
import { useRecoilValue } from 'recoil';
import session from 'state/session';

export function Route({
    path,
    children,
}: {
    path: string;
    children: ReactNode;
    featureFlag?: FeatureFlag;
}) {
    const { path: fullPath, params, knownPath } = useRouteContext(path);

    const paramSections = path.split('/').reverse();
    const newParams = paramSections.reduce((params, part, index) => {
        if (knownPath.length)
            if (part.startsWith(':')) {
                params[part.replace(':', '')] = decodeURIComponent(
                    knownPath[knownPath.length - index - 1]
                );
            }

        return params;
    }, params);

    return (
        <RouteContextProvider path={fullPath} params={newParams}>
            {children}
        </RouteContextProvider>
    );
}

Route.displayName = 'Route';

const paramRegex = /(:[a-z]+)/gi;
export function replacePath(path: string) {
    return path.replace(paramRegex, '[^/]+');
}

export function Switch({ children }) {
    const [, reRender] = useState(0);

    useEffect(() => {
        function onPopState() {
            reRender((n) => n + 1);
        }
        window.addEventListener('popstate', onPopState);
        return () => window.removeEventListener('popstate', onPopState);
    }, []);
    let Route: React.ReactElement = null;
    let featureFlag: string;
    const { path: parentPath } = useContext(RouteContext);
    const pathName = locationProvider().pathname;
    React.Children.forEach(children, (child, index) => {
        const fullPath = `${parentPath}/${child.props.path}`;
        if (!Route && new RegExp(replacePath(fullPath), 'gi').test(pathName)) {
            Route = child;
            featureFlag = child.props?.featureFlag;
        }
    });

    return featureFlag ? (
        <FeatureProtectedRoute feature={featureFlag}>
            {Route}
        </FeatureProtectedRoute>
    ) : (
        Route
    );
}

const FeatureProtectedRoute = ({ feature, children }) => {
    const hasFeature = useFeatureFlag(feature);
    const userSession = useRecoilValue(session);

    useEffect(() => {
        let timeout: NodeJS.Timeout;
        // user is not logged in or does not have the correct feature to see the route.
        if (!userSession) {
            const { pathname, search } = window.location;
            timeout = setTimeout(
                () =>
                    navigateAbsolute(NavigationPage.Welcome, false, {
                        pathname,
                        search,
                    }),
                0
            );
        }
        return () => {
            clearTimeout(timeout);
        };
    }, [userSession]);
    return hasFeature ? children : null;
};
