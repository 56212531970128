import { useRef } from 'react';
import Api, { useHttpClient } from './api';

class AccountApi {
    private api;
    constructor(api: Api) {
        this.api = api;
    }

    createAccount = async (idToken: string) => {
        return await this.api.post('/v1/account', { idToken });
    };
}

const useAccountApi = () => {
    const httpClient = useHttpClient();
    const accountApi = useRef(new AccountApi(httpClient));
    return accountApi.current;
};

export { useAccountApi };
