import { lazy, useEffect } from 'react';
import styles from './App.module.scss';
import { useFormContext, FormStateProvider } from 'state/formState';
import { ToastContainer } from 'util/toast/toast';
import { ConfirmContainer } from 'util/confirm/confirm';
import classNames from 'util/classNames';
import {
    navigate,
    Route,
    Suspense,
    SuspenseFallback,
    Switch,
} from 'components/display/Router';
import Logo from 'components/display/Logo';
import { ReactComponent as Menu } from 'images/menu.svg';
import Dialog from 'components/display/Dialog';
import Button from 'components/display/Button';
import { RecoilRoot, useRecoilState, useRecoilValue } from 'recoil';
import sessionState from 'state/session';
import { NavigationPage } from 'pages/NavigationPage';
import { useOpenPassClient } from 'util/OpenPass';
import OpenPassRedirect from 'pages/OpenPassRedirect/OpenPassRedirect';
import { FeatureFlagProvider } from 'state/featureFlag';
import HttpApiClient from 'util/api';
import LogOut from 'components/display/LogOut';

const Landing = lazy(() => import('pages/Landing'));
const AuthCode = lazy(() => import('pages/AuthCode'));
const Profile = lazy(() => import('pages/Profile'));
const Success = lazy(() => import('pages/Success'));
const Home = lazy(() => import('pages/Home'));
const Feedback = lazy(() => import('pages/Feedback'));

window.addEventListener('load', function () {
    setTimeout(function () {
        // This hides the address bar:
        window.scrollTo(0, 1);
    }, 0);
});

function App() {
    useEffect(() => {
        const { height, width } = window.screen;
        const viewport = document.querySelector('meta[name=viewport]');
        viewport.setAttribute(
            'content',
            `width=${width}, height=${height - 100}, initial-scale=1`
        );
    }, []);

    return (
        <RecoilRoot>
            <FeatureFlagProvider>
                <FormStateProvider>
                    <AppRouter />
                    <ConfirmContainer />
                    <ToastContainer />
                </FormStateProvider>
            </FeatureFlagProvider>
        </RecoilRoot>
    );
}

function AppRouter() {
    const [session, setSession] = useRecoilState(sessionState);

    useEffect(() => {
        if (!session?.expires) {
            return;
        }
        const now = Math.floor(new Date().getTime() / 1000);
        const needsRefresh = session.expires < now;
        let cancelled = false;
        const refresh = async () => {
            const client = new HttpApiClient({});
            const newTokens = await client.post<
                {
                    refreshToken: string;
                    idToken: string;
                    idTokenExpires: number;
                },
                { grantType: 'refresh'; refreshToken: string }
            >('/auth', {
                grantType: 'refresh',
                refreshToken: session.refreshToken,
            });
            if (cancelled) {
                return;
            }
            setSession({ ...newTokens, email: session.email });
        };

        if (needsRefresh) {
            refresh();
        } else {
            const timeout = setTimeout(refresh, (session.expires - now) * 1000);
            return () => {
                cancelled = true;
                clearTimeout(timeout);
            };
        }
    }, [session, setSession]);

    const { isFormEnabled } = useFormContext();

    return (
        <>
            <div className={styles.appFilter} />

            <div
                className={classNames(
                    styles.appWrapper,
                    styles.enabled,
                    !isFormEnabled && styles.appDisabled
                )}
            >
                <header className={styles.appHeader}>
                    <Logo
                        className={styles.logo}
                        onClick={() => navigate('/')}
                    />
                    {session && (
                        <Dialog content={<ExpandedMenu />}>
                            <Button isFlat className={styles.menuButton}>
                                <Menu />
                            </Button>
                        </Dialog>
                    )}
                </header>
                {!isFormEnabled && <SuspenseFallback />}
                <div className={styles.appContentWrapper}>
                    <Suspense>
                        <Switch>
                            <Route path={NavigationPage.Welcome}>
                                <Landing className={styles.appContent} />
                            </Route>
                            <Route path={NavigationPage.AuthCode}>
                                <AuthCode className={styles.appContent} />
                            </Route>
                            <Route path={NavigationPage.Activate}>
                                <AuthCode className={styles.appContent} />
                            </Route>
                            <Route path={NavigationPage.Profile}>
                                <Profile className={styles.appContent} />
                            </Route>
                            <Route path={NavigationPage.Success}>
                                <Success className={styles.appContent} />
                            </Route>
                            <Route path={NavigationPage.Home}>
                                <Home className={styles.appContent} />
                            </Route>
                            <Route path={NavigationPage.OpenPassRedirect}>
                                <OpenPassRedirect />
                            </Route>

                            <Route path={NavigationPage.Feedback}>
                                <Feedback className={styles.appContent} />
                            </Route>
                            <Route path={'*'}>
                                <LostPage />
                            </Route>
                        </Switch>
                    </Suspense>
                </div>
                <footer className={styles.appFooter}>
                    <div>
                        <p>
                            Copyright Placeholder Terms and Conditions | Privacy
                            policy
                        </p>
                    </div>
                </footer>
            </div>
        </>
    );
}

export function ExpandedMenu() {
    const session = useRecoilValue(sessionState);

    return <div className={styles.expandedMenu}>{session && <LogOut />} </div>;
}

export function LostPage() {
    const { handleRedirect } = useOpenPassClient();
    useEffect(() => {
        (async () => {
            const clientState = await handleRedirect();
            if (clientState?.pathname) {
                return navigate(
                    `${clientState.pathname}${clientState.search}`,
                    false
                );
            }
            if (['/', ''].includes(window.location.pathname)) {
                navigate(NavigationPage.Home);
            }
        })();
    }, [handleRedirect]);

    return <>'Lost you?'</>;
}

export default App;
