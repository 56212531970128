import { navigateAbsolute } from 'components/display/Router';
import { NavigationPage } from 'pages/NavigationPage';
import { useCallback } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import toast, { ToastType } from 'util/toast';
import { useFormContext } from 'state/formState';
import session, { UserSession } from 'state/session';
import { registrationState } from 'state/setup';
import logFactory from 'util/Log';
import { useAccountApi } from 'util/api/account';
import { useAuthApi } from 'util/api/auth';

const { error } = logFactory('OpenPassClient.ts');

function handleError(err: Error) {
    error(err);
    window.scrollTo(0, 1);
    toast(err.message, { type: ToastType.Error });
}

class AuthChangeManager {
    private subscribers: Set<ChangeHandler> = new Set();

    change(authChange: AuthResponse) {
        this.subscribers.forEach((s) => {
            try {
                s(authChange);
            } catch (e) {
                console.error('error handling auth change event', e);
            }
        });
    }

    subscibe(handler: ChangeHandler) {
        this.subscribers.add(handler);
        return () => this.subscribers.delete(handler);
    }
}

const changeManager = new AuthChangeManager();

const authClient = new window['openpass'].OpenPassAuth({
    clientId: process.env.REACT_APP_OPENPASS_CLIENTID,
    onAuthChange: (authChange: AuthResponse) =>
        changeManager.change(authChange),
    baseUrl: process.env.REACT_APP_OPENPASS_BASE_URL,
    uid2BaseUrl: process.env.REACT_APP_UID2_BASE_URL,
    enableUid2Integration: false,
});

type AuthResponse = {
    authenticated: boolean;
    advertising_token: string;
};

type ChangeHandler = (authChange: AuthResponse) => void;

const useOpenPassClient = () => {
    const { setFormIsDisabled, setFormIsEnabled } = useFormContext();
    const setRegState = useSetRecoilState(registrationState);
    const [user, setSession] = useRecoilState<UserSession>(session);
    const accountApi = useAccountApi();
    const authApi = useAuthApi();
    const handleRedirect = useCallback(async () => {
        if (await authClient.isRedirect()) {
            const result = await authClient.handleRedirect();
            if (result.authenticated) {
                await accountApi.createAccount(result.rawIdToken);
                const authResponse = await authApi.signIn(result.rawIdToken);
                const newSession = {
                    ...authResponse,
                    email: result.idToken.email,
                };
                setSession(newSession);
                return result.clientState;
            }
        }
    }, [authApi, setSession, accountApi]);

    const signOut = useCallback(() => {
        (async () => {
            setFormIsDisabled();
            try {
                await authClient.signOut();
                setSession({});
                navigateAbsolute(NavigationPage.Welcome);
            } catch (err) {
                handleError(err);
            } finally {
                setRegState({
                    hasSignedIn: false,
                    hasSignedUp: false,
                    hasCreatedAMainProfile: false,
                    hasRegisteredDevice: false,
                    hasCompletedSetup: false,
                });
                setFormIsEnabled();
            }
        })();
    }, [setFormIsDisabled, setFormIsEnabled, setRegState, setSession]);

    const signIn = useCallback(() => {
        (async () => {
            await authClient.signInWithRedirect({
                redirectUrl: `${window.location.protocol}//${window.location.host}/open-pass-redirect`,
                clientState: window.history.state,
            });
        })();
    }, []);

    return { user, setSession, signIn, handleRedirect, signOut };
};

export default authClient;

export { useOpenPassClient };
