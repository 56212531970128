import Button from "components/display/Button";
import styles from './confirm.module.scss'
import { ReactNode, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import classNames from "util/classNames";
import { ReactComponent as Close } from 'images/close.svg';

class ConfirmManager {
    confirm = null as ReactNode;
    confirmCount = 0;
    handler = null as Function;

    private processHandler() {
        this.handler();
    }

    pushConfirm = (
        message: ReactNode,
        confirmCallback: Function,
        confirmText: string = 'Confirm',
        cancelText: string = 'Cancel'
    ) => {
        this.confirm = (
            <Confirm
                message={message}
                confirmText={confirmText}
                cancelText={cancelText}
                key={this.confirmCount++}
                confirmCallback={confirmCallback}
                close={() => confirmManager.removeConfirm()}
            />
        );
        this.processHandler();
    };

    removeConfirm = () => {
        this.confirm = null;
        this.processHandler();
    };
    getConfirm = () => {
        return this.confirm;
    };
    subscribe = (handler) => {
        this.handler = handler;
        return () => {
            this.handler = null;
        }
    }
}

function Confirm({message, confirmCallback, confirmText = 'Confirm', cancelText = 'Cancel', close}) {
    const _this = useRef<HTMLDivElement>();
    return (
        <div ref={_this} className={classNames(styles.confirm)}>
            <Close className={styles.closer} onClick={close} role='button'/>
            {message}
            <Button 
                className={classNames(styles.button, styles.confirmButton)} 
                onClick={() => {
                    confirmCallback();
                    close();
                }} 
                role='confirm-button'
            >
                {confirmText}
            </Button>
            <Button 
                className={classNames(styles.button, styles.cancelButton)} 
                onClick={close}
                role='cancel-button'
            >
                {cancelText}
            </Button>
        </div>
    );
}

const confirmManager = new ConfirmManager();

export default function confirm(
    message: ReactNode, 
    confirmCallback: Function, 
    confirmText: string = 'Confirm', 
    cancelText: string = 'Cancel'
) {
    confirmManager.pushConfirm(message, confirmCallback, confirmText, cancelText)
}

export function ConfirmContainer() {
    const [ , render] = useState(0);
    
    useEffect(() => {
        return confirmManager.subscribe(() => render((n) => n + 1))
    }, []);

    return createPortal(
        <div className={styles.confirmContainer}>{confirmManager.getConfirm()}</div>,
        document.body
    );
}

export { confirmManager };