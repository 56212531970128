import React, { MouseEventHandler, ReactNode, useRef } from 'react';
import classNames from '../../../util/classNames';
import styles from './Button.module.scss';
interface ButtonProps {
    className?: string;
    style?: React.CSSProperties;
    onClick?: MouseEventHandler;
    isFlat?: boolean;
    children: ReactNode;
    type?: 'button' | 'submit';
    tabIndex?: number;
    role?: string;
    disabled?: boolean;
}

export default function Button({
    className,
    style,
    onClick,
    isFlat,
    children,
    type = 'button',
    tabIndex,
    role,
    disabled,
}: ButtonProps) {
    let classes = [className, styles.button];
    if (isFlat) {
        classes.push(styles.flatButton);
    }
    const buttonRef = useRef(null);

    return (
        <button
            tabIndex={tabIndex}
            type={type}
            ref={buttonRef}
            onClick={onClick}
            style={style}
            className={classNames(...classes)}
            role={role}
            disabled={disabled}
        >
            {children}
        </button>
    );
}
