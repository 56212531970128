import bridgewater from 'images/bridgewater.svg';
import { MouseEventHandler } from 'react';

interface LogoProps {
    className?: string;
    onClick?: MouseEventHandler;
}

export default function Logo({ className, onClick }: LogoProps) {
    return (
        <img
            src={bridgewater}
            alt='BridgeWater'
            className={className}
            onClick={onClick}
        />
    );
}
